import React from 'react';
import { useFinanceFaqs } from '../finance-provider';
import useCollapse from 'react-collapsed';
import { Helmet } from 'react-helmet';

interface Props {
  sectionName: string;
}

const FAQ = (props) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  return (
    <div className={`collapsible ${isExpanded ? 'is-expanded' : ''}`}>
      <div className="collapsible-heading">
        <div className="contentFaq__question">{props.title}</div>
        <button className="btnToggle" {...getToggleProps()}>
          <span className="icon-plus">
            <span />
          </span>
        </button>
      </div>
      <div className="collapsible-collapse" {...getCollapseProps()}>
        <div className="collapsible-body">{props.children}</div>
      </div>
    </div>
  );
};

export const FinanceFAQPage = React.memo<Props>(({ sectionName }) => {
  const faqSection = useFinanceFaqs()?.find(
    (f) => f.sectionName === sectionName
  );
  const faqSchema = faqSection?.categories.map((faqCategory, index) => {
    const test = index;
    const faqList = faqCategory.faqs.map((faq, faqIndex) => {
      const faqfillerIndex = faqIndex;
      const schema = {
        '@type': 'Question',
        name: `${faq.question}`,
        acceptedAnswer: {
          '@type': 'Answer',
          text: `${faq.answer}`,
        },
      };
      return schema;
    });
    return faqList;
  });
  const schemaTest = { ...faqSchema }['0'];
  return (
    <section id="faqs" className="faqs">
      {!!faqSection &&
      faqSection.isVisible &&
      faqSection.categories.length > 0 ? (
        <>
          <Helmet>
            <script type="application/ld+json">{`
              {
                  "@context": "http://schema.org",
                  "@type": "FAQPage",
                  "mainEntity": ${JSON.stringify(schemaTest)}
              }
          `}</script>
          </Helmet>
          <div
            className="container"
            style={{ paddingLeft: 15, paddingRight: 15 }}
          >
            <h2 className="h2 faqs__title" style={{ marginTop: 20 }}>
              {!!faqSection.title ? `${faqSection.title}` : 'Finance FAQs'}
            </h2>
            <div className="contentFaq">
              {faqSection.groupList ? (
                faqSection.categories.map((faqCategory, index) => (
                  <div key={index} style={{ marginBottom: 20 }}>
                    <h3 style={{ paddingBottom: 0 }}>{faqCategory.category}</h3>
                    <ul className="contentFaq__items">
                      {faqCategory.faqs.map((faq, faqIndex) => (
                        <li key={faqIndex} className="contentFaq__item">
                          <FAQ title={faq.question}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: faq.answer,
                              }}
                            />
                          </FAQ>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))
              ) : (
                <ul className="contentFaq__items">
                  {faqSection.categories.map((faqCategory) =>
                    faqCategory.faqs.map((faq, faqIndex) => (
                      <li key={faqIndex} className="contentFaq__item">
                        <FAQ title={faq.question}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: faq.answer,
                            }}
                          />
                        </FAQ>
                      </li>
                    ))
                  )}
                </ul>
              )}
            </div>
          </div>
        </>
      ) : (
        <div />
      )}
    </section>
  );
});
