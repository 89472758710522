import React, { useState, useEffect, useCallback } from 'react';
import { useHashRouter } from '~/common/hooks/use-hashrouter';
import { Button, SvgIcon } from '@ui-elements';
import { Link } from 'common/components';
import { useRoutes } from '../finance-provider';
import { RouteInfo } from '../../models';
import { scroller } from 'react-scroll';
import { queryStringToJSON } from '~/common/utils/formatters';
import styles from './index.module.scss';

export const FinanceTabNavigation = React.memo(() => {
  const router = useHashRouter();
  const routes = useRoutes();

  const [activeTab, setActiveTab] = useState<RouteInfo>();
  const [menuVisible, setMenuVisible] = useState<boolean>(false);

  const onLoadCallback = useCallback(() => {
    if (typeof window !== 'undefined') {
      const idx = routes.findIndex((r) => r.path === window.location.pathname);
      if (idx >= 0) setActiveTab(routes[idx]);
    }
  }, []);
  useEffect(() => onLoadCallback(), [onLoadCallback]);

  const activeTabCallback = useCallback(() => {
    if (activeTab) {
      const query =
        window.location.search.length > 0 ? window.location.search : null;
      const param = query ? { params: queryStringToJSON(query) } : undefined;
      router.navigate(activeTab.path, param);
    }
  }, [activeTab]);
  useEffect(() => activeTabCallback(), [activeTabCallback]);

  const redirectCallback = useCallback(() => {
    if (router.activeRoute && router.activeRoute !== activeTab?.path) {
      const idx = routes.findIndex((r) => r.path === router.activeRoute);
      if (idx >= 0) setActiveTab(routes[idx]);
    }
  }, [router.activeRoute]);
  useEffect(() => redirectCallback(), [redirectCallback]);

  useEffect(() => {
    if (menuVisible) {
      window.scroll(0, 0);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [menuVisible]);

  const handleSetActiveRoute = (route: RouteInfo) => {
    setActiveTab(route);
  };

  const setActiveLink = (route: RouteInfo) => {
    return activeTab?.path === route.path ||
      (activeTab?.name === 'calculators' && route.name === 'financial-services')
      ? styles.LinkActive
      : styles.LinkInactive;
  };

  const navigationMenu = (isMobile: boolean) => (
    <div className={styles.FinanceTab_menu}>
      <ul>
        {routes.map((route, index) => {
          if (!!route?.isRedirectLink) {
            return (
              <li key={index}>
                <a className={setActiveLink(route)} href={route.path}>
                  {route.text}
                </a>
              </li>
            );
          }
          return (
            !route.hidden && (
              <li key={index}>
                <Link
                  to={route.path}
                  onClick={() => {
                    window.location.replace(route.path);
                  }}
                  className={setActiveLink(route)}
                  isRedirectLink={route.isRedirectLink || true}
                >
                  {route.text}
                </Link>
              </li>
            )
          );
        })}
      </ul>
      {isMobile ? (
        <></>
      ) : (
        <Button
          buttonSize="large"
          type={isMobile ? 'secondary' : 'primary'}
          onClick={() => {
            setMenuVisible(false);
            const idx = routes.findIndex(
              (r) => r.path === '/financial-services/calculators'
            );
            if (idx >= 0) {
              window.location.replace('/financial-services/calculators');
            }
          }}
        >
          Calculate Your Finance
        </Button>
      )}
    </div>
  );

  const navigationLabel = (isClose: boolean) => (
    <div
      className={styles.FinanceTab_labelActive}
      onClick={() => {
        setMenuVisible(!menuVisible);
      }}
    >
      <label>{activeTab?.text}</label>

      {isClose ? (
        <SvgIcon type="chevronDown" size="18px" />
      ) : (
        <SvgIcon type="chevronUp" size="18px" />
      )}
    </div>
  );

  return (
    <>
      <div className={styles.FinanceTab}>
        <div className="container mx-auto h-full">
          <div className={styles.FinanceTab_mobile}>
            {navigationLabel(true)}
            {menuVisible && (
              <div className={styles.FinanceTab_menuWrapper}>
                {navigationLabel(false)}
                {navigationMenu(true)}
              </div>
            )}
          </div>
          <div className={styles.FinanceTab_desktop}>
            {navigationMenu(false)}
          </div>
        </div>
      </div>
      <div className={styles.FinanceCalculatorCTA_mobile}>
        <Button
          buttonSize="large"
          type={'secondary'}
          onClick={() => {
            const idx = routes.findIndex(
              (r) => r.path === '/financial-services/calculators'
            );
            if (idx >= 0) {
              window.location.replace('/financial-services/calculators');
            }
          }}
        >
          Calculate Your Finance
        </Button>
      </div>
    </>
  );
});
