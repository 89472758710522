import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import loadable from '@loadable/component';
import { FinanceDisclaimer } from '../finance-disclaimer';
import { FinanceFeatureTile } from '../finance-feature-tile';
import { FinanceFAQPage } from '../finance-faq-page';
import { FinanceWhySubaruHeader } from '../finance-why-subaru-header';
import { useWhySubaruFinance } from '../finance-provider';
import styles from './index.module.scss';
import { Events, scroller } from 'react-scroll';
import { useHashRouter } from '~/common/hooks/use-hashrouter';

const FinanceVehicleCarousel = loadable(
  () => import('../finance-calculator-carousel'),
  {
    resolveComponent: (component) => component.FinanceVehicleCarousel,
  }
);

interface Props {
  scrollToCalculators?: boolean;
}

export const FinanceWhySubaruPage = React.memo<Props>(
  ({ scrollToCalculators }) => {
    const [disableUpdateToUrl, setDisableUpdateToUrl] = useState(true);
    const financePage = useWhySubaruFinance();
    const router = useHashRouter();

    useEffect(() => {
      Events.scrollEvent.register('end', () => setDisableUpdateToUrl(false));
      Events.scrollEvent.register('begin', () => setDisableUpdateToUrl(true));

      return () => {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
      };
    }, []);

    const scrollToCalculatorsCallback = useCallback(() => {
      if (scrollToCalculators) {
        router.navigate('/financial-services/calculators');
        setTimeout(() => {
          scroller.scrollTo('vehicle-carousel', {
            smooth: true,
            offset: -90,
            ignoreCancelEvents: true,
          });
        }, 300);
      }
    }, [scrollToCalculators]);
    useEffect(
      () => scrollToCalculatorsCallback(),
      [scrollToCalculatorsCallback]
    );

    const handleSectionVisible = (url: string) => {
      if (!disableUpdateToUrl || !scrollToCalculators)
        window.history.pushState({}, '', url);
    };

    return (
      <div className={cn(styles.WhySubaru)}>
        <FinanceWhySubaruHeader {...financePage} />

        {financePage?.otherFeatures &&
          financePage?.otherFeatures.map((feature, index) => (
            <FinanceFeatureTile
              tileColor="primary"
              buttonType="borderedSecondary"
              rtl={index % 2 !== 0}
              key={index}
              {...feature}
              onSectionVisible={handleSectionVisible}
            />
          ))}

        <FinanceVehicleCarousel onSectionVisible={handleSectionVisible} />

        <FinanceFAQPage sectionName={'WhySubaru'} />

        <FinanceDisclaimer />
      </div>
    );
  }
);
