import React from 'react';
import cn from 'classnames';
import { SvgIcon } from '@ui-elements';
import { WhySubaruFinance } from '../../models';
import styles from './index.module.scss';

type Props = WhySubaruFinance;

export const FinanceWhySubaruHeader = React.memo<Props>((props) => (
  <section className={styles.Header}>
    <div className="container mx-auto">
      <div className={styles.Header_inner}>
        <div className={styles.Header_content}>
          <div className={cn(styles.Titles)}>
            <h2 dangerouslySetInnerHTML={{ __html: props.heading as string }} />
            <div
              dangerouslySetInnerHTML={{ __html: props.subHeading as string }}
            />
          </div>

          <div className={styles.Feature}>
            <ul>
              <li>
                <SvgIcon
                  size="40px"
                  color="#0042a6"
                  className={styles.Feature_icon}
                  type="expert"
                />
                <p>{props.feature1}</p>
              </li>
              <li>
                <SvgIcon
                  size="40px"
                  color="#0042a6"
                  className={styles.Feature_icon}
                  type="mechanic"
                />
                <p>{props.feature2}</p>
              </li>
              <li>
                <SvgIcon
                  size="40px"
                  color="#0042a6"
                  className={styles.Feature_icon}
                  type="thumbsUp"
                />
                <p>{props.feature3}</p>
              </li>
            </ul>
          </div>

          {props.brochureDownloadLink && (
            <div className={styles.BrochureDownload}>
              <SvgIcon
                className={styles.Download_icon}
                type="download"
                color="#0042a6"
              />
              <a
                href={props.brochureDownloadLink}
                rel="noreferrer"
                target="_blank"
              >
                Download a brochure
              </a>
            </div>
          )}
        </div>

        <div className={styles.Header_image}>
          <img src={props.bannerImage} alt={props.heading} />
        </div>
      </div>
    </div>
  </section>
));
