import React from 'react';
import cn from 'classnames';
import { PageHero } from 'common/components';
import { usePageContent } from '../finance-provider';
import styles from './index.module.scss';

export const FinanceHeroContent = React.memo(() => {
  const pageContent = usePageContent();

  return (
    <div className={styles.HeroContent}>
      <PageHero
        src={pageContent?.heroImage as string}
        alt={pageContent?.heading}
      >
        <div className={cn('container mx-auto', styles.HeroContent_wrapper)}>
          <div className={styles.HeroContent_inner}>
            <div
              dangerouslySetInnerHTML={{
                __html: pageContent?.heading as string,
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: pageContent?.headingText as string,
              }}
            />
          </div>
        </div>
      </PageHero>
    </div>
  );
});
