import React from 'react';
import { VehicleVariant, FAQSection } from '~/common/models';
import {
  FinanceViewModel,
  FinancePageContent,
  WhySubaruFinance,
  GFV,
  ApiBaseBath,
  RouteInfo,
} from '../../models';

type FinanceProviderContext = FinanceViewModel & {
  vehicleVariants: VehicleVariant[];
  routes: RouteInfo[];
};

export const FinanceProvider = React.createContext<FinanceProviderContext>({
  pageContent: null,
  subaruFinance: null,
  gfv: null,
  vehicleModels: [],
  vehicleVariants: [],
  faqs: [],
  apiBasePath: null,
  routes: [],
});

export const usePageContent = (): FinancePageContent | null => {
  const context = React.useContext(FinanceProvider);
  return context.pageContent;
};

export const useWhySubaruFinance = (): WhySubaruFinance | null => {
  const context = React.useContext(FinanceProvider);
  return context.subaruFinance;
};

export const useGFV = (): GFV | null => {
  const context = React.useContext(FinanceProvider);
  return context.gfv;
};

export const useVehicleModels = () => {
  const { vehicleModels, vehicleVariants } = React.useContext(FinanceProvider);

  const getVariant = (variantId: string) => {
    return vehicleVariants.find(
      (x) => x.variantId === variantId || x.modelId === variantId
    );
  };

  const getModelVariants = (modelId: string) => {
    return vehicleVariants.filter((x) => x.modelId === modelId);
  };

  return { vehicleModels, vehicleVariants, getVariant, getModelVariants };
};

export const useFinanceFaqs = (): FAQSection[] | null => {
  const context = React.useContext(FinanceProvider);
  return context.faqs ?? [];
};

export const useApiBasePath = (): ApiBaseBath | null => {
  const context = React.useContext(FinanceProvider);
  return context.apiBasePath;
};

export const useRoutes = (): RouteInfo[] => {
  const context = React.useContext(FinanceProvider);
  return context.routes;
};
