import React from 'react';
import cn from 'classnames';
import { Button, ButtonElement } from '@ui-elements';
import { OtherFeature } from '../../models';
import styles from './index.module.scss';
import { useRoutes } from '../finance-provider';
import { useHashRouter } from '~/common/hooks/use-hashrouter';
import { Waypoint } from 'react-waypoint';

interface FeatureTileProps {
  rtl?: boolean;
  tileColor?: 'primary' | 'concrete';
  buttonType?: 'primary' | 'borderedSecondary';
  onSectionVisible?: (url: string) => void;
}

type Prop = FeatureTileProps & OtherFeature;

export const FinanceFeatureTile = React.memo<Prop>(
  ({
    rtl,
    title,
    image,
    description,
    ctaButtons,
    buttonType,
    tileColor = 'primary',
    onSectionVisible,
  }) => {
    const router = useHashRouter();
    const routes = useRoutes();

    const handleSectionVisible = (url: string) => {
      if (onSectionVisible) onSectionVisible(url);
    };

    return (
      <section className={styles[`FeatureTile_${tileColor}`]}>
        <Waypoint
          topOffset={200}
          bottomOffset={500}
          onEnter={() => handleSectionVisible('/financial-services')}
        >
          <div
            className={cn(
              'container mx-auto',
              rtl ? styles.FeatureTile_rtl : styles.FeatureTile_ltr
            )}
          >
            <div className={styles.FeatureTile_spacer}>
              <img className={styles.FeatureTile_img} src={image} alt={title} />
            </div>
            <div className={styles.FeatureTile_content}>
              <div>
                <h3 className={styles.FeatureTile_contentTitle}>{title}</h3>
                <div
                  className={styles.FeatureTile_contentText}
                  dangerouslySetInnerHTML={{ __html: description as string }}
                />
                <div className={styles.FeatureTile_ctas}>
                  {ctaButtons &&
                    ctaButtons?.map(
                      (btn, index) =>
                        btn.visible && (
                          <Button
                            key={index}
                            href={btn.link}
                            type={buttonType}
                            target={btn.target}
                            className={cn('MultiCTA ', styles.FeatureTile_btn)}
                            buttonSize="large"
                            reloads
                          >
                            {btn.text}
                          </Button>
                        )
                    )}
                </div>
              </div>
            </div>
          </div>
        </Waypoint>
      </section>
    );
  }
);
