import React from 'react';
import loadable from '@loadable/component';
import { Loading } from '@ui-elements';
import {
  HashRouter,
  Route,
  BrochureAndDealer,
  PageLayout,
  RedirectContainer,
} from 'common/components';
import { FinanceHeroContent } from './components/finance-hero-content';
import { FinanceTabNavigation } from './components/finance-tab-navigation';
import { FinanceWhySubaruPage } from './components/finance-why-subaru-page';
import { FinanceProvider } from './components/finance-provider';
import { VehicleVariant } from '~/common/models';
import { FinanceViewModel, RouteInfo } from './models';
import { getVehiclePrice } from '~/common/services/pricing-service';
import { usePostcode } from '~/common/hooks/use-postcode';
import styles from './index.modules.scss';

const FinanceContactUsPage = loadable(
  () => import('./components/finance-contact-us-page'),
  {
    resolveComponent: (component) => component.FinanceContactUsPage,
    fallback: <Loading />,
  }
);

const FinanceCalculatorPage = loadable(
  () => import('./components/finance-calculator-page'),
  {
    resolveComponent: (component) => component.FinanceCalculatorPage,
    fallback: <Loading />,
  }
);

const FinanceGFVPage = loadable(() => import('./components/finance-gfv-page'), {
  resolveComponent: (component) => component.FinanceGFVPage,
  fallback: <Loading />,
});

export const Finance: React.FC<FinanceViewModel> = (props) => {
  React.useEffect(() => {
    window['pushDigitalData']({
      event: '_pageLoaded',
    });
  }, []);

  return (
    <FinanceStore {...props}>
      <HashRouter disableHash>
        <PageLayout>
          <div className={styles.Finance}>
            <FinanceHeroContent />
            <FinanceTabNavigation />

            <RedirectContainer
              routes={[
                {
                  path: '#/finance',
                  redirectTo: '/financial-services/calculators',
                },
                {
                  path: '#/gfv',
                  redirectTo: '/financial-services/guaranteed-future-value',
                },
                {
                  path: '#/contact-us',
                  redirectTo: '/financial-services/contact',
                },
              ]}
            >
              <Route path="/financial-services">
                <FinanceWhySubaruPage />
              </Route>
              <Route path="/financial-services/calculators">
                <FinanceWhySubaruPage scrollToCalculators />
              </Route>
              <Route path="/financial-services/guaranteed-future-value">
                <FinanceGFVPage />
              </Route>
              <Route path="/financial-services/contact">
                <FinanceContactUsPage />
              </Route>
              <Route path="/financial-services/calculator">
                <FinanceCalculatorPage />
              </Route>
            </RedirectContainer>

            <BrochureAndDealer />
          </div>
        </PageLayout>
      </HashRouter>
    </FinanceStore>
  );
};

const FinanceStore: React.FC<FinanceViewModel> = (props) => {
  const { postcode } = usePostcode();
  const [vehicleVariants, setVehicles] = React.useState<VehicleVariant[]>([]);
  const routes: RouteInfo[] = [
    {
      path: '/financial-services',
      name: 'financial-services',
      text: 'Subaru Financial Services',
    },
    {
      path: '/financial-services/guaranteed-future-value',
      name: 'guaranteed-future-value',
      text: 'Guaranteed Future Value',
    },
    {
      path: '/financial-services/personal-car-finance',
      name: 'personal-car-finance',
      text: 'Personal',
      isRedirectLink: true,
    },
    {
      path: '/financial-services/car-finance-for-businesses',
      name: 'car-finance-for-businesses',
      text: 'Business',
      isRedirectLink: true,
    },
    {
      path: '/financial-services/financial-assistance',
      name: 'financial-assistance',
      text: 'Financial Assistance',
      isRedirectLink: true,
    },
    {
      path: '/financial-services/contact',
      name: 'contact',
      text: 'Contact Us',
    },
    {
      path: '/financial-services/calculator',
      name: 'calculator',
      text: 'Calculate Your Finance',
      hidden: true,
    },
    {
      path: '/financial-services/calculators',
      name: 'calculators',
      text: 'Calculate Your Finance',
      hidden: true,
    },
  ];

  React.useEffect(() => {
    (async function () {
      if (!props.vehicleModels) return;

      const vehicleVaraints = await props.vehicleModels.reduce(
        async (newVvs, model) => {
          const vvs: VehicleVariant[] = [];

          if (model.variants) {
            for (const vv of model.variants) {
              const price = await getVehiclePrice(
                vv.vehicleId as string,
                postcode
              );

              vvs.push({
                modelId: model.vehicleId as string,
                modelName: model.vehicleName as string,
                modelBadge: model.vehicleBadge as string,
                modelImage: model.vehicleImage as string,

                variantId: vv.vehicleId as string,
                variantImage: vv.vehicleImage as string,
                variantName: vv.vehicleName as string,
                variantNameAlt: vv.vehicleNameAlt as string,
                variantRDP: price?.rdp ?? 0,
                variantMLP: price?.mlp ?? 0,
              });
            }
          }

          return [...(await newVvs), ...vvs];
        },
        Promise.resolve([] as VehicleVariant[])
      );

      setVehicles(vehicleVaraints);
    })();
  }, [postcode]);

  const contextValue = { ...props, vehicleVariants, routes };

  return (
    <FinanceProvider.Provider value={contextValue}>
      {props.children}
    </FinanceProvider.Provider>
  );
};
