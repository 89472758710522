import React from 'react';
import { usePageContent } from '../finance-provider';
import styles from './index.module.scss';

interface Props {
  children?: React.ReactNode;
}

export const FinanceDisclaimer = React.memo<Props>(({ children }) => {
  const pageContent = usePageContent();

  return (
    <section className={styles.Disclaimer}>
      <div
        className="container mx-auto"
        {...(!children
          ? {
              dangerouslySetInnerHTML: {
                __html: pageContent?.legalDisclaimer as string,
              },
            }
          : {})}
      >
        {children && <p>{children}</p>}
      </div>
    </section>
  );
});
